<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    ids: {
      type: Array,
      required: false,
      default: () => []
    },
    statColumns: {
      type: Object,
    },
    enableDefaults:{
      type: Boolean,
      required: false,
    },
    assignmentMode: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    allowSelect: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    selected:{
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showOnlyBookmarks: false,
    
    }
  },
  computed: {
    ...mapGetters('instruments', {
      order: 'order',
      loading: 'loading',
      processing: 'processing',
      classifiers: 'classifiers',
      items: 'items',
      hasAccess: 'hasAccess',
      defaults: 'defaults',
      normalized: 'normalized',
     
    }),
     anyProcessing() {
        const keys = Object.keys(this.processing);
        return keys.find(key => this.processing[key]) ? true : false;
      },
    isBusy () {
      return this.loading;
    },
    
  },
  methods: {
    ...mapActions('instruments', {
      removeAccess: 'removeAccess',
      addAccess: 'addAccess',
      addDefault: 'addDefault',
      removeDefault: 'removeDefault',
      setOrder: 'setOrder'
    }),
    async addAccessForAll() {
      this.items.forEach(async i => {
        if(!this.hasAccess[i.id]) {
          await this.addAccess(i.id);
        }
      });
    },
    async removeAccessForAll() {
      this.items.forEach(async i => {
        if(this.hasAccess[i.id]) {
          await this.removeAccess(i.id);
        }
      });
    },
    async addDefaultForAll() {
      this.items.forEach(async i => {
        if(this.hasAccess[i.id] && !this.defaults[i.id]) {
          await this.addDefault(i.id);
        }
      });
    },
    async removeDefaultForAll() {
      this.items.forEach(async i => {
        if(this.hasAccess[i.id] && this.defaults[i.id]) {
          await this.removeDefault(i.id);
        }
      });
    },
    select(instrument) {
      this.$emit('select', instrument);
    },
    unselect(instrument) {
      this.$emit('unselect', instrument);
    },
    onOrder(colName) {
      const o = { ...this.order };

      if(o.by === colName) {
        o.asc = !o.asc;
      } else {
        o.by = colName;
        o.asc = true;
      }

      this.setOrder(o);
    }
  }
};
</script>

<template>
  <div class="table-responsive">
    
    <table class="table table-centered nowrap">
      <thead>
        <tr>
          <th class="text-center" v-if="allowSelect" style="width: 80px; max-width: 80px;">
            <b>Selected</b>
          </th>
          <th class="text-center" style="width: 150px; max-width: 150px;" v-if="assignmentMode">
           <b>Access</b>
            <div class="input-group no-wrap" style="flex-wrap:nowrap">
               <button class="btn  btn-sm btn-c8 revers" style="min-width:35px; width:35px" @click="addAccessForAll" :disabled="anyProcessing">
                <span class="btn-content" v-if="anyProcessing">
                  <i class="fa fa-spin fa-spinner" /> 
                </span>
                <span class="btn-content" v-if="!anyProcessing">
                    <i class="fa fa-plus"/> 
                </span>
              </button>
              
              <button class="btn  btn-sm btn-c8 ml-2" style="min-width:35px; width:35px" @click="removeAccessForAll"  :disabled="anyProcessing">
                <span class="btn-content" v-if="anyProcessing">
                  <i class="fa fa-spin fa-spinner" />
                </span>
                <span class="btn-content" v-if="!anyProcessing">
                    <i class="fa fa-minus"/>
                </span>
              </button>
            </div>
           
          </th>
          <th class="text-center" style="width: 80px; max-width: 80px;" v-if="assignmentMode && enableDefaults">
            <b>Default</b>
            <div class="input-group no-wrap" style="flex-wrap:nowrap">
               <button class="btn  btn-sm btn-c8 revers" style="min-width:35px; width:35px" @click="addDefaultForAll" :disabled="anyProcessing">
                <span class="btn-content" v-if="anyProcessing">
                  <i class="fa fa-spin fa-spinner" /> 
                </span>
                <span class="btn-content" v-if="!anyProcessing">
                    <i class="fa fa-plus"/> 
                </span>
              </button>
              
              <button class="btn  btn-sm btn-c8 ml-2" style="min-width:35px; width:35px" @click="removeDefaultForAll"  :disabled="anyProcessing">
                <span class="btn-content" v-if="anyProcessing">
                  <i class="fa fa-spin fa-spinner" />
                </span>
                <span class="btn-content" v-if="!anyProcessing">
                    <i class="fa fa-minus"/>
                </span>
              </button>
            </div>
          </th>
          <th style="min-width: 200px; cursor: pointer;" @click="onOrder('Name')">
            Name
            <span class="ml-2" v-if="order.by === 'Name'">
              <i class="mdi mdi-sort-alphabetical-ascending" v-if="order.asc"></i>
              <i class="mdi mdi-sort-alphabetical-descending" v-if="!order.asc"></i>
            </span>
          </th>
          <template v-for="c in classifiers.instrumentItems"  >
            <th 
              :key="c.id"
              style="white-space: pre; cursor: pointer;"  @click="onOrder(c.id)"
              v-if="(statColumns[c.id] || {}).visible"
            >
              {{c.title}}
              <span class="ml-2" v-if="order.by === c.id">
                <i class="mdi mdi-sort-alphabetical-ascending" v-if="order.asc"></i>
                <i class="mdi mdi-sort-alphabetical-descending" v-if="!order.asc"></i>
              </span>
            </th>
          </template>
          
          <th style="cursor:pointer;max-width:90px;min-width:90px;white-space: normal;" @click="onOrder('ytd')" v-if="statColumns.ytd.visible">
            <span class="mr-1">{{$t('tables.indices.columns.ytd')}}</span>
            <span class="float-right" v-if="order.by === 'ytd'" >
              <i class="mdi mdi-sort-alphabetical-ascending" v-if="order.asc"></i>
              <i class="mdi mdi-sort-alphabetical-descending" v-if="!order.asc"></i>
            </span>
          </th>
          <th style="cursor:pointer;max-width:90px;min-width:90px;white-space: normal;" @click="onOrder('mtd')" v-if="statColumns.mtd.visible">
            <span class="mr-1">{{$t('tables.indices.columns.mtd')}}</span>
            <span class="float-right" v-if="order.by === 'mtd'" >
              <i class="mdi mdi-sort-alphabetical-ascending" v-if="order.asc"></i>
              <i class="mdi mdi-sort-alphabetical-descending" v-if="!order.asc"></i>
            </span>
          </th>
          <th style="cursor:pointer;max-width:90px;white-space: normal;" @click="onOrder('annualised-return')" v-if="statColumns.annualisedReturn.visible">
            <span class="mr-1">{{$t('tables.indices.columns.annualisedReturn')}}</span>
            <span class="float-right" v-if="order.by === 'annualised-return'" >
              <i class="mdi mdi-sort-alphabetical-ascending" v-if="order.asc"></i>
              <i class="mdi mdi-sort-alphabetical-descending" v-if="!order.asc"></i>
            </span>
          </th>
          <th style="cursor:pointer;max-width:100px;min-width:100px;white-space: normal;" @click="onOrder('realised-volatility')" v-if="statColumns.realisedVolatility.visible">
            <span class="mr-1">{{$t('tables.indices.columns.realisedVolatility')}}</span>
            <span class="float-right" v-if="order.by === 'realised-volatility'" >
              <i class="mdi mdi-sort-alphabetical-ascending" v-if="order.asc"></i>
              <i class="mdi mdi-sort-alphabetical-descending" v-if="!order.asc"></i>
            </span>
          </th>
          <th  style="cursor:pointer;max-width:110px;min-width:110px;white-space: normal;;"  @click="onOrder('downside-volatility')" v-if="statColumns.downsideVolatility.visible">
            <span class="mr-1">{{$t('tables.indices.columns.downsideVolatility')}}</span>
            <span class="float-right" v-if="order.by === 'downside-volatility'" >
              <i class="mdi mdi-sort-alphabetical-ascending" v-if="order.asc"></i>
              <i class="mdi mdi-sort-alphabetical-descending" v-if="!order.asc"></i>
            </span>
          </th>
          <th style="cursor:pointer;max-width:110px;min-width:110px;white-space: normal;" @click="onOrder('max-drawdown')" v-if="statColumns.maxDrawdown.visible">
            <span class="mr-1">{{$t('tables.indices.columns.maxDrawdown')}}</span>
            <span class="float-right" v-if="order.by === 'max-drawdown'" >
              <i class="mdi mdi-sort-alphabetical-ascending" v-if="order.asc"></i>
              <i class="mdi mdi-sort-alphabetical-descending" v-if="!order.asc"></i>
            </span>
          </th>
          <th style="cursor:pointer;max-width:110px;min-width:110px;white-space: normal;" @click="onOrder('winning-months')" v-if="statColumns.winningMonths.visible">
            <span class="mr-1">{{$t('tables.indices.columns.winningMonths')}}</span>
            <span class="float-right" v-if="order.by === 'winning-months'" >
              <i class="mdi mdi-sort-alphabetical-ascending" v-if="order.asc"></i>
              <i class="mdi mdi-sort-alphabetical-descending" v-if="!order.asc"></i>
            </span>
          </th>
          <th style="cursor:pointer;max-width:90px;min-width:90px;white-space: normal;" @click="onOrder('sharpe-ratio')" v-if="statColumns.sharpeRatio.visible">
            <span class="mr-1">{{$t('tables.indices.columns.sharpeRatio')}}</span>
            <span class="float-right" v-if="order.by === 'sharpe-ratio'" >
              <i class="mdi mdi-sort-alphabetical-ascending" v-if="order.asc"></i>
              <i class="mdi mdi-sort-alphabetical-descending" v-if="!order.asc"></i>
            </span>
          </th>
          <th style="cursor:pointer;max-width:90px;min-width:90px;white-space: normal;" @click="onOrder('sortino-ratio')" v-if="statColumns.sortinoRatio.visible">
            <span class="mr-1">{{$t('tables.indices.columns.sortinoRatio')}}</span>
            <span class="float-right" v-if="order.by === 'sortino-ratio'" >
              <i class="mdi mdi-sort-alphabetical-ascending" v-if="order.asc"></i>
              <i class="mdi mdi-sort-alphabetical-descending" v-if="!order.asc"></i>
            </span>
          </th>
          <th style="cursor:pointer;max-width:90px;min-width:90px;white-space: normal;" @click="onOrder('calmar-ratio')" v-if="statColumns.calmarRatio.visible">
            <span class="mr-1">{{$t('tables.indices.columns.calmarRatio')}}</span>
            <span class="float-right" v-if="order.by === 'calmar-ratio'" >
              <i class="mdi mdi-sort-alphabetical-ascending" v-if="order.asc"></i>
              <i class="mdi mdi-sort-alphabetical-descending" v-if="!order.asc"></i>
            </span>
          </th>
          <th style="cursor:pointer;max-width:90px;min-width:90px;white-space: normal;" v-if="statColumns.externalId.visible">
            <span class="mr-1">{{$t('tables.indices.columns.externalId')}}</span>
           
          </th>
        </tr>
      </thead>
      
      <tbody>
        <template v-for="item in items">
          <tr :key="item.id" class="success">
            <td style="width: 80px; max-width: 80px;" class="text-center" v-if="allowSelect">

              <i class="font-size-24 mdi mdi-check-bold" v-if="selected[item.id]"></i>
              <button class="btn  btn-sm btn-c8 revers" v-if="!selected[item.id]" @click="select(item)">
                <span class="btn-content">
                  Select
                </span>
              </button>
            </td>
            <td class="text-center" style="width: 150px; max-width: 150px;" v-if="assignmentMode">
              <button class="btn  btn-sm btn-c8 revers" 
                v-if="!hasAccess[item.id]" 
                @click="addAccess(item.id)"
                :disabled="processing[item.id]"
              >
                <span class="btn-content" v-if="processing[item.id]">
                  <i class="fa fa-spin fa-spinner" />
                </span>
                <span class="btn-content" v-if="!processing[item.id]">
                    <i class="fa fa-plus"/>
                </span>
              </button>
              <button class="btn  btn-sm btn-c8" 
                v-if="hasAccess[item.id]" 
                :disabled="processing[item.id]"
                @click="removeAccess(item.id)"
              >
                <span class="btn-content" v-if="processing[item.id]">
                  <i class="fa fa-spin fa-spinner" />
                </span>
                <span class="btn-content" v-if="!processing[item.id]">
                    <i class="fa fa-minus"/>
                </span>
              </button>
            </td>
            <td class="text-center" style="width: 80px; max-width: 80px;" v-if="assignmentMode && enableDefaults">
              <button class="btn  btn-sm btn-c8 revers" v-if="!defaults[item.id] && hasAccess[item.id]" @click="addDefault(item.id)">
                <span class="btn-content" v-if="processing[item.id]">
                  <i class="fa fa-spin fa-spinner" />
                </span>
                <span class="btn-content" v-if="!processing[item.id]">
                    <i class="fa fa-plus"/>
                </span>
              </button>
              <button class="btn  btn-sm btn-c8" v-if="defaults[item.id] && hasAccess[item.id]" @click="removeDefault(item.id)">
                <span class="btn-content" v-if="processing[item.id]">
                  <i class="fa fa-spin fa-spinner" />
                </span>
                <span class="btn-content" v-if="!processing[item.id]">
                    <i class="fa fa-minus"/>
                </span>
              </button>
            </td>
            <td class="text-nowrap">
              <router-link :to="{ name: 'instrument-view', params: {instrumentId: item.id}}">
                {{item.name}}
              </router-link> 
             
            </td>
            <template v-for="c in classifiers.instrumentItems"  >
              <td  :key="c.id"  v-if="(statColumns[c.id] || {}).visible">
                <span v-if="item.classifiers[c.id]">
                  {{c.map[item.classifiers[c.id]]}}
                </span>
              </td>
            </template>

          
            <td v-if="statColumns.ytd.visible">
              <div v-if="normalized  && item.data && item.data.performance"> {{item.data.performance.normalizedYtdFormatted}} </div>
              <div v-if="!normalized  && item.data && item.data.performance"> {{item.data.performance.ytdFormatted}} </div>
            </td>
            <td v-if="statColumns.mtd.visible">
              <div v-if="normalized && item.data && item.data.performance"> {{item.data.performance.normalizedMtdFormatted}} </div>
              <div v-if="!normalized && item.data  && item.data.performance"> {{item.data.performance.mtdFormatted}} </div>
            </td>
           
            <td v-if="statColumns.annualisedReturn.visible">
              <div v-if="normalized && item.data  && item.data.mainStatistic"> {{item.data.mainStatistic.normalizedValues.annualisedReturnFormatted}}% </div>
              <div v-if="!normalized && item.data  && item.data.mainStatistic"> {{item.data.mainStatistic.values.annualisedReturnFormatted}}% </div>
            </td>
            <td v-if="statColumns.realisedVolatility.visible">
              <div v-if="normalized && item.data  && item.data.mainStatistic"> {{item.data.mainStatistic.normalizedValues.realisedVolatilityFormatted}}% </div>
              <div v-if="!normalized && item.data  && item.data.mainStatistic"> {{item.data.mainStatistic.values.realisedVolatilityFormatted}}% </div>
            </td>
            <td v-if="statColumns.downsideVolatility.visible">
              <div v-if="normalized && item.data  && item.data.mainStatistic"> {{item.data.mainStatistic.normalizedValues.downsideVolatilityFormatted}}% </div>
              <div v-if="!normalized && item.data  && item.data.mainStatistic"> {{item.data.mainStatistic.values.downsideVolatilityFormatted}}% </div>
            </td>
          
            <td v-if="statColumns.maxDrawdown.visible">
              <div v-if="normalized && item.data  && item.data.mainStatistic"> {{item.data.mainStatistic.normalizedValues.maxDrawdownFormatted}}% </div>
              <div v-if="!normalized && item.data  && item.data.mainStatistic"> {{item.data.mainStatistic.values.maxDrawdownFormatted}}% </div>
            </td>
            <td v-if="statColumns.winningMonths.visible">
              <div v-if="normalized && item.data && item.data.mainStatistic"> {{item.data.mainStatistic.normalizedValues.winningMonthsFormatted}}% </div>
              <div v-if="!normalized && item.data && item.data.mainStatistic"> {{item.data.mainStatistic.values.winningMonthsFormatted}}% </div>
            </td>
            <td v-if="statColumns.sharpeRatio.visible">
              <div v-if="normalized && item.data && item.data.mainStatistic"> {{item.data.mainStatistic.normalizedValues.sharpeRatioFormatted}} </div>
              <div v-if="!normalized && item.data && item.data.mainStatistic"> {{item.data.mainStatistic.values.sharpeRatioFormatted}} </div>
            </td>
            <td v-if="statColumns.sortinoRatio.visible">
              <div v-if="normalized && item.data && item.data.mainStatistic"> {{item.data.mainStatistic.normalizedValues.sortinoRatioFormatted}} </div>
              <div v-if="!normalized && item.data && item.data.mainStatistic"> {{item.data.mainStatistic.values.sortinoRatioFormatted}} </div>
            </td>
            <td v-if="statColumns.calmarRatio.visible">
              <div v-if="normalized && item.data && item.data.mainStatistic"> {{item.data.mainStatistic.normalizedValues.calmarRatioFormatted}} </div>
              <div v-if="!normalized && item.data && item.data.mainStatistic"> {{item.data.mainStatistic.values.calmarRatioFormatted}} </div>
            </td>
            <td v-if="statColumns.externalId.visible">
              {{(item.systemValues || {}).ExternalId}}
            </td>
          </tr>
        </template>
        
      </tbody>
    </table>
  </div>
</template>