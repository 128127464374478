<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {

  },
  computed: {
    ...mapGetters('instruments', {
      loading: 'loading',
      classifiers: 'classifiers',
      filter: 'filter',
      isStatFiltered: 'isStatFiltered'
    }),
    isBusy () {
      return this.loading;
    },
    mtdFrom:
    {
      get() {
        return this.filter.mtdFrom;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {mtdFrom: newValue || null}});
      }
    },
    ytdFrom:
    {
      get() {
        return this.filter.ytdFrom;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {ytdFrom: newValue || null}});
      }
    },
    annualisedReturnFrom:
   {
      get() {
        return this.filter.annualisedReturnFrom;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {annualisedReturnFrom: newValue || null}});
      }
    },
    realisedVolatilityFrom:
    {
      get() {
        return this.filter.realisedVolatilityFrom;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {realisedVolatilityFrom: newValue || null}});
      }
    },
    downsideVolatilityFrom:
   {
      get() {
        return this.filter.downsideVolatilityFrom;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {downsideVolatilityFrom: newValue || null}});
      }
    },
    maxDrawdownFrom:
   {
      get() {
        return this.filter.maxDrawdownFrom;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {maxDrawdownFrom: newValue || null}});
      }
    },
    winningMonthsFrom:
    {
      get() {
        return this.filter.winningMonthsFrom;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {winningMonthsFrom: newValue || null}});
      }
    },
    sharpeRatioFrom:
    {
      get() {
        return this.filter.sharpeRatioFrom;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {sharpeRatioFrom: newValue || null}});
      }
    },
    sortinoRatioFrom:
    {
      get() {
        return this.filter.sortinoRatioFrom;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {sortinoRatioFrom: newValue}});
      }
    },
    calmarRatioFrom:
    {
      get() {
        return this.filter.calmarRatioFrom;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {calmarRatioFrom: newValue || null}});
      }
    },
    mtdTill:
    {
      get() {
        return this.filter.mtdTill;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {mtdTill: newValue || null}});
      }
    },
    ytdTill:
    {
      get() {
        return this.filter.ytdTill;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {ytdTill: newValue || null}});
      }
    },
    annualisedReturnTill:
    {
      get() {
        return this.filter.annualisedReturnTill;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {annualisedReturnTill: newValue || null}});
      }
    },
    realisedVolatilityTill:
    {
      get() {
        return this.filter.realisedVolatilityTill;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {realisedVolatilityTill: newValue || null}});
      }
    },
    downsideVolatilityTill:
    {
      get() {
        return this.filter.downsideVolatilityTill;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {downsideVolatilityTill: newValue || null}});
      }
    },
    maxDrawdownTill:
    {
      get() {
        return this.filter.maxDrawdownTill;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {maxDrawdownTill: newValue || null}});
      }
    },
    winningMonthsTill:
   {
      get() {
        return this.filter.winningMonthsTill;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {winningMonthsTill: newValue || null}});
      }
    },
    sharpeRatioTill:
    {
      get() {
        return this.filter.sharpeRatioTill;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {sharpeRatioTill: newValue || null}});
      }
    },
    sortinoRatioTill:
    {
      get() {
        return this.filter.sortinoRatioTill;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {sortinoRatioTill: newValue || null}});
      }
    },
    calmarRatioTill:
    {
      get() {
        return this.filter.calmarRatioTill;
      },
      set (newValue) {
        this.setFilter({...this.filter, ... {calmarRatioTill: newValue || null}});
      }
    },
    isESGQuickFilterEnabled() {
      const indexType1 = (this.filter.classifiers.indexType || []).find(x => x === 'ESG');
      const indexSubType1 = (this.filter.classifiers["indexType/indexSubType"] || []).find(x => x === "MarketCap/ESG")
      return indexType1 && indexSubType1;
    },
    isCommodsQuickFilterEnabled() {
      const assetClass1 = (this.filter.classifiers.assetClass || []).find(x => x === 'Commodity');
      const assetClass2 = (this.filter.classifiers.assetClass || []).find(x => x === 'FuturesCommodities');
      return assetClass1 && assetClass2;
    },
    isAlphaQuickFilterEnabled () {
      const indexType1 = (this.filter.classifiers.indexType || []).find(x => x === 'ESG');
      const indexType2 = (this.filter.classifiers.indexType || []).find(x => x === 'RiskParity');
      const indexType3 = (this.filter.classifiers.indexType || []).find(x => x === 'RiskPremia');
      const indexType4 = (this.filter.classifiers.indexType || []).find(x => x === 'StatisticalArbitrage');
      const indexType5 = (this.filter.classifiers.indexType || []).find(x => x === 'TrendFollowing');
      const instrumentType1 = (this.filter.classifiers.instrumentType || []).find(x => x === 'ActiveIndex');
      const instrumentType2 = (this.filter.classifiers.instrumentType || []).find(x => x === 'Overlay');

      return indexType1
        && indexType2
        && indexType3
        && indexType4
        && indexType5
        && instrumentType1
        && instrumentType2;
    },
    isCryptoQuickFilterEnabled () {
      const cryptoCurrencyAsset = (this.filter.classifiers.assetClass || []).find(x => x === 'CryptoCurrency');
      const instrumentTypeCrypto = (this.filter.classifiers.instrumentType || []).find(x => x === 'Crypto');
      return cryptoCurrencyAsset
       && instrumentTypeCrypto;
    },
    isThematicQuickFilterEnabled () {
      const indexType2 = (this.filter.classifiers.indexType || []).find(x => x === 'Thematic');
      return indexType2;
    },
    isGlobalETFsQuickFilterEnabled () {
      const market = (this.filter.classifiers.geography|| []).find(x => x === 'Global');
      const indexType = (this.filter.classifiers.indexType|| []).find(x => x === 'ETF');
      return market && indexType;
    },
  },
  data() {
    return {
      isReady: false,
      filters:[]
    };
  },
   watch: { 
    p_criteria: {
      handler(newValue, oldValue) {
        if(newValue !== oldValue) {
          console.log('p_criteria changed', { newValue, oldValue });
          this.setFilter({...this.filter, ...{ stat: this.p_criteria}});
        }
        
      },
      deep: true
    },
    filter(newValue, oldValue) {
      if(newValue !== oldValue) {
        console.log('filter changed', {newValue, oldValue});
      }
    }
  },
  methods: {
    ...mapActions('instruments', {
      setFilter: 'setFilter',
      reload: 'reload',
      resetFilter: 'resetFilter'
    }),
    applyQuickFilter(name) {
      //debugger; // eslint-disable-line no-debugger
      let onlyReset = false;
      if (name === 'crypto' && this.isCryptoQuickFilterEnabled) {
        onlyReset = true;
      }
      if(name === 'globalETFs' && this.isGlobalETFsQuickFilterEnabled) {
        onlyReset = true;
      }
      if(name === 'thematic' && this.isThematicQuickFilterEnabled) {
        onlyReset = true;
      }
      if (name === 'alpha' && this.isAlphaQuickFilterEnabled) {
        onlyReset = true;
      }
      if (name === 'commods' && this.isCommodsQuickFilterEnabled) {
        onlyReset = true;
      }
      if (name === 'esg' && this.isESGQuickFilterEnabled) {
        onlyReset = true;
      }
      
      this.resetFilter();
      
      if(!onlyReset) {
        if(name === 'crypto') {
          this.updateClassifier('assetClass', 'CryptoCurrency');
          this.updateClassifier('instrumentType', 'Crypto');
        } 
        else if(name === 'commods') {
          this.updateClassifier('assetClass', 'Commodity');
          this.updateClassifier('assetClass', 'FuturesCommodities');
        }
        else if(name === 'esg') {
          this.updateClassifier('indexType', 'ESG');
          this.updateClassifier('indexType', 'MarketCap');
          this.updateClassifier('indexType', 'MarketCap', 'indexSubType', 'ESG');
        }
        else  if(name === 'globalETFs') {
          this.updateClassifier('geography', 'Global');
          this.updateClassifier('indexType', 'ETF');
        } else  if(name === 'thematic') {
          this.updateClassifier('indexType', 'Thematic');
        } else  if(name === 'alpha') {
          this.updateClassifier('indexType', 'ESG');
          this.updateClassifier('indexType', 'RiskParity');
          this.updateClassifier('indexType', 'RiskPremia');
          this.updateClassifier('indexType', 'StatisticalArbitrage');
          this.updateClassifier('indexType', 'TrendFollowing');
          this.updateClassifier('instrumentType', 'ActiveIndex');
          this.updateClassifier('instrumentType', 'Overlay');
        }
      }
    },
    isChecked(classifierId, classifierValue, subClassifierId, subClassifierValue){
      let key = classifierId;
      let value = classifierValue;
      if(subClassifierId) {
        key += '/' + subClassifierId;
      }
       if(subClassifierValue) {
        value += '/' + subClassifierValue;
      }
      return (this.filter.classifiers[key] || []).find(x => x === value);
    },
    updateClassifier(classifierId, classifierValue, subClassifierId, subClassifierValue) {
      let key = classifierId;
      let value = classifierValue;
      if(subClassifierId) {
        key += '/' + subClassifierId;
      }
       if(subClassifierValue) {
        value += '/' + subClassifierValue;
      }

      const f = JSON.parse(JSON.stringify(this.filter));
      let a = f.classifiers[key] || [];

      let isAdded = false;
      if(a.find(x => x === value)){
        a = a.filter(x => x !== value);
      }else{
        isAdded = true;
        a = [...a, value];
      }
      //debugger; // eslint-disable-line no-debugger
      if(subClassifierId) {
        let _key = classifierId;
        let _value = classifierValue;
        let _a = f.classifiers[_key] || [];
        if(isAdded) {
          if(!_a.find(x => x === _value)){
           _a = [..._a, _value];
           f.classifiers[_key] = _a;
          }
        }
      }

      f.classifiers[key] = a;
      this.setFilter(f);
    }
  }
};
</script>

<template>
  <div class="card p-0 cardc8">
    <div class="card-content m-3">
      <div class="card-header bg-transparent d-flex justify-content-between"><h5>Filters</h5></div>

      <div class="row mb-4 mr-2 ml-2">
        <div class="col-md-6">
          <b-form-group>
            <button 
              class="btn btn-block  px-1"
              @click="applyQuickFilter('alpha')"
              :class="{'btn-outline-secondary': !isAlphaQuickFilterEnabled, 'btn-primary': isAlphaQuickFilterEnabled}"
              title="Select a shortlist of active indices that seek excess risk adjusted returns.">
                <i class="mdi mdi-filter-outline mr-1" v-if="!isAlphaQuickFilterEnabled"/>
                <i class="mdi mdi-filter mr-1" v-if="isAlphaQuickFilterEnabled"/>  
                Alpha
            </button>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group>
            <button 
              class="btn btn-block  px-1"
              :class="{'btn-outline-secondary': !isThematicQuickFilterEnabled, 'btn-primary': isThematicQuickFilterEnabled}"
                @click="applyQuickFilter('thematic')"
                title="Explore indices that focus on a theme, such as water security or ESG."
            >
              <i class="mdi mdi-filter-outline mr-1" v-if="!isThematicQuickFilterEnabled"/>
              <i class="mdi mdi-filter mr-1" v-if="isThematicQuickFilterEnabled"/>
              Thematic
            </button>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group>
            <button 
              class="btn  btn-block  px-1" 
              :class="{'btn-outline-secondary': !isGlobalETFsQuickFilterEnabled, 'btn-primary': isGlobalETFsQuickFilterEnabled}"
              title="Global coverage ETFs not tied to a particular region, with underlying investments in equities, fixed income or alternatives."
              @click="applyQuickFilter('globalETFs')"
            >
              <i class="mdi mdi-filter-outline mr-1" v-if="!isGlobalETFsQuickFilterEnabled"/>
              <i class="mdi mdi-filter mr-1" v-if="isGlobalETFsQuickFilterEnabled"/>
              Global ETFs
            </button>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group>
            <button 
              class="btn  btn-block  px-1" 
              :class="{'btn-outline-secondary': !isESGQuickFilterEnabled, 'btn-primary': isESGQuickFilterEnabled}"
              title="Selecting on environmental, social, and governance criteria is in high demand with the potential for the portfolio to perform."
              @click="applyQuickFilter('esg')"
            >
              <i class="mdi mdi-filter-outline mr-1" v-if="!isESGQuickFilterEnabled"/>
              <i class="mdi mdi-filter mr-1" v-if="isESGQuickFilterEnabled"/>
              ESG
            </button>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group>
            <button 
              class="btn  btn-block  px-1" 
              :class="{'btn-outline-secondary': !isCommodsQuickFilterEnabled, 'btn-primary': isCommodsQuickFilterEnabled}"
              title="Commodities provide a diversified alternatives to equities and fixed income, either through ETFs or through a systematic process."
              @click="applyQuickFilter('commods')"
            >
              <i class="mdi mdi-filter-outline mr-1" v-if="!isCommodsQuickFilterEnabled"/>
              <i class="mdi mdi-filter mr-1" v-if="isCommodsQuickFilterEnabled"/>
              Commods
            </button>
          </b-form-group>
        </div>
        
        <div class="col-md-6">
          <b-form-group>
            <button 
              class="btn  btn-block px-1" 
              :class="{'btn-outline-secondary': !isCryptoQuickFilterEnabled, 'btn-primary': isCryptoQuickFilterEnabled}"
              title="Select crypto currencies available on the C8 platform, excluding our proprietary overlays."
              @click="applyQuickFilter('crypto')"
            >
              <i class="mdi mdi-filter-outline mr-1" v-if="!isCryptoQuickFilterEnabled"/>
              <i class="mdi mdi-filter mr-1" v-if="isCryptoQuickFilterEnabled"/>
              Crypto
            </button>
          </b-form-group>
        </div>
      
      </div> 
      
      <b-form-group
        class="mt-2" 
        label-for="type"
      >
        <div class="search-box">
          <div class="position-relative">
            <input type="text" class="form-control rounded" 
              :value="filter.keyword"
              placeholder="Search...."  
              @input="(event) => setFilter({...filter, keyword: event.target.value})" 
              v-on:keyup.enter="reload"
              id="keywords"/>
            <i class="mdi mdi-magnify search-icon"></i>
          </div>
        </div>
      </b-form-group>
      <div id="accordion" class="custom-accordion categories-accordion">

        <div class="categories-group-card" v-for="(classifier, ci) in classifiers.filterItems" :key="ci">
          <a v-b-toggle="classifier.id" class="categories-group-list">
            
            <span class="mr-2">{{classifier.title}}</span>
            <small class="text-primary" v-if="(filter.classifiers[classifier.id] || []).length > 0">
              <b>[<i class="mdi mdi-filter"></i>]</b>
            </small>
            <i class="mdi mdi-minus float-right accor-plus-icon"></i>
          </a>
          <b-collapse :id="classifier.id" data-parent="#accordion" :accordion="classifier.id">
            <div class="pl-3 pb-2">
                <div class="custom-control custom-checkbox" v-for="(value, vi) in classifier.values" :key="classifier.id + vi" 
                  @click="updateClassifier(classifier.id, value.value)">
                  <input type="checkbox" class="custom-control-input" 
                    :checked="isChecked(classifier.id, value.value)" 
                  >
                  <label class="custom-control-label" >{{value.text}}</label>

                  <div class="small " :key="value.value + '_children'" v-if="classifier.children[value.value] && classifier.children[value.value].length > 0">
                    <div v-for="subClassifier in classifier.children[value.value]" :key="classifier.id + subClassifier.id">
                      <div class="custom-control custom-checkbox" v-for="subValue in subClassifier.values" :key="classifier.id + value.value + subClassifier.id + subValue.value" 
                        @click.stop="updateClassifier(classifier.id, value.value, subClassifier.id, subValue.value)">
                        <input type="checkbox" class="custom-control-input" 
                          :checked="isChecked(classifier.id, value.value, subClassifier.id, subValue.value)" 
                        >
                        <label class="custom-control-label" >{{subValue.text}}</label>
                       </div>
                    </div>
                  </div>
                </div>
            </div>
          </b-collapse>          
        </div>

        <div class="categories-group-card">
          <a v-b-toggle.statistics class="categories-group-list">
            
            <span class="mr-2">{{$t('tables.indices.statistics')}}</span>
            <small class="text-primary" v-if="isStatFiltered">[Filter enabled]</small>
            <i class="mdi mdi-minus float-right accor-plus-icon"></i>
          </a>

          <b-collapse id="statistics" data-parent="#accordion" accordion="statistics" style="position:relative;">
              <div class="pl-3 pb-2 pr-3">
              <b-form-group
                class="mt-2"
                :label="$t('tables.indices.columns.mtd')"
                label-for="type"
              >
                <div class="d-flex flex-row">
                  <div class="flex-fill pr-1">
                    <input class="form-control" type="number" v-model.number="mtdFrom"/>
                  </div>
                  <div class="flex-fill pl-1">
                    <input class="form-control" type="number" v-model.number="mtdTill"/>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                class="mt-2"
                :label="$t('tables.indices.columns.ytd')"
                label-for="type"
              >
                <div class="d-flex flex-row">
                  <div class="flex-fill pr-1">
                    <input class="form-control" type="number" v-model.number="ytdFrom"/>
                  </div>
                  <div class="flex-fill pl-1">
                    <input class="form-control" type="number" v-model.number="ytdTill"/>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                class="mt-2"
                :label="$t('tables.indices.columns.annualisedReturn')"
                label-for="type"
              >
                <div class="d-flex flex-row">
                  <div class="flex-fill pr-1">
                    <input class="form-control" type="number" v-model.number="annualisedReturnFrom"/>
                  </div>
                  <div class="flex-fill pl-1">
                    <input class="form-control" type="number" v-model.number="annualisedReturnTill"/>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                class="mt-2"
                :label="$t('tables.indices.columns.realisedVolatility')"
                label-for="type"
              >
                <div class="d-flex flex-row">
                  <div class="flex-fill pr-1">
                    <input class="form-control" type="number" v-model.number="realisedVolatilityFrom"/>
                  </div>
                  <div class="flex-fill pl-1">
                    <input class="form-control" type="number" v-model.number="realisedVolatilityTill"/>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                class="mt-2"
                :label="$t('tables.indices.columns.downsideVolatility')"
                label-for="type"
              >
                <div class="d-flex flex-row">
                  <div class="flex-fill pr-1">
                    <input class="form-control" type="number" v-model.number="downsideVolatilityFrom"/>
                  </div>
                  <div class="flex-fill pl-1">
                    <input class="form-control" type="number" v-model.number="downsideVolatilityTill"/>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                class="mt-2"
                :label="$t('tables.indices.columns.maxDrawdown')"
                label-for="type"
              >
                <div class="d-flex flex-row">
                  <div class="flex-fill pr-1">
                    <input class="form-control" type="number" v-model.number="maxDrawdownFrom"/>
                  </div>
                  <div class="flex-fill pl-1">
                    <input class="form-control" type="number" v-model.number="maxDrawdownTill"/>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                class="mt-2"
                :label="$t('tables.indices.columns.winningMonths')"
                label-for="type"
              >
                <div class="d-flex flex-row">
                  <div class="flex-fill pr-1">
                    <input class="form-control" type="number" v-model.number="winningMonthsFrom"/>
                  </div>
                  <div class="flex-fill pl-1">
                    <input class="form-control" type="number" v-model.number="winningMonthsTill"/>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                class="mt-2"
                :label="$t('tables.indices.columns.sharpeRatio')"
                label-for="type"
              >
                <div class="d-flex flex-row">
                  <div class="flex-fill pr-1">
                    <input class="form-control" type="number" v-model.number="sharpeRatioFrom"/>
                  </div>
                  <div class="flex-fill pl-1">
                    <input class="form-control" type="number" v-model.number="sharpeRatioTill"/>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                class="mt-2"
                :label="$t('tables.indices.columns.sortinoRatio')"
                label-for="type"
              >
                <div class="d-flex flex-row">
                  <div class="flex-fill pr-1">
                    <input class="form-control" type="number" v-model.number="sortinoRatioFrom"/>
                  </div>
                  <div class="flex-fill pl-1">
                    <input class="form-control" type="number" v-model.number="sortinoRatioTill"/>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                class="mt-2"
                :label="$t('tables.indices.columns.calmarRatio')"
                label-for="type"
              >
                <div class="d-flex flex-row">
                  <div class="flex-fill pr-1">
                    <input class="form-control" type="number" v-model.number="calmarRatioFrom"/>
                  </div>
                  <div class="flex-fill pl-1">
                    <input class="form-control" type="number" v-model.number="calmarRatioTill"/>
                  </div>
                </div>
              </b-form-group>
            </div>
          </b-collapse>
        </div>
      </div>
      <div class="buttons mt-4">
        <button class=" mt-2 btn btn-c8 btn-block btn-sm" @click="reload" :disabled="loading">
          <span class="btn-content"> Apply Filter</span>
        </button>
        <button type="button" class=" mt-2 float-right btn btn-sm btn-link" @click="resetFilter"> Reset filters </button></div>
    </div>
  </div>
</template>